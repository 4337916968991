// src/i18n.js
import { createI18n } from 'vue-i18n';
// 自动导入locales文件夹下的所有语言文件
function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en', 
  messages: loadLocaleMessages(), // 初始为空，动态加载
  // warnHtmlMessage: false, // 禁用 HTML 警告
});

export default i18n;
