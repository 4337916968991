import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  // 多语言主路由
  {
    path: '/:lang',
    redirect: (to) => ({
      path: `/${to.params.lang}/warranty`,
      replace: true,
    }),
    children: [
      // 默认重定向
      {
        path: '',
        redirect: (to) => ({
          path: `/${to.params.lang}/warranty`,
          replace: true,
        }),
      },
      // 登录相关路由
      {
        path: 'login',
        name: 'Login',
        // 按语言动态加载组件（可选）
        component: () =>
          import(/* webpackChunkName: "login" */ '@/pages/Login/LoginPage.vue'),
        meta: {
          title: 'Login',
          hideMenu: true,
          requiresAuth: false,
        },
      },
      {
        path: 'forgetPassword',
        name: 'ForgetPassword',
        component: () =>
          import(
            /* webpackChunkName: "forgetPassword" */ '@/pages/Login/ForgetPassword.vue'
          ),
        meta: {
          title: 'Forget Password',
          hideMenu: true,
          requiresAuth: false,
        },
      },
      {
        path: 'register',
        name: 'Register',
        component: () =>
          import(
            /* webpackChunkName: "register" */ '@/pages/Login/CreateAccount.vue'
          ),
        meta: {
          title: 'Register',
          hideMenu: true,
          requiresAuth: false,
        },
      },
      // 业务路由
      {
        path: 'warranty',
        name: 'Warranty',
        component: () =>
          import(
            /* webpackChunkName: "warranty" */ '@/pages/Warranty/DeviceList.vue'
          ),
        meta: {
          label: 'route.deviceList',
          title: 'Guide steps',
          keepAlive: true,
          requiresAuth: true,
        },
      },
      {
        path: 'warrantyDetail',
        name: 'WarrantyDetail',
        component: () =>
          import(
            /* webpackChunkName: "warrantyDetail" */ '@/pages/Warranty/ProductDetails.vue'
          ),
        meta: {
          title: 'Product Details',
          keepAlive: true,
          hideMenu: true,
          requiresAuth: true,
        },
      },
      // 404处理
      {
        path: ':catchAll(.*)*',
        redirect: (to) => ({
          path: `/${to.params.lang}/warranty`,
          replace: true,
        }),
      },
    ],
  },
  // 根路径兜底
  {
    path: '',
    redirect: () => {
      const defaultLang = localStorage.getItem('language') || 'en';
      return `/${defaultLang}`;
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
