<template>
  <div id="app">
    <keep-alive v-if="$route.meta.keepAlive" :key="key">
      <ResponsiveLayout v-if="!$route.meta.isResponsive">
        <router-view />
      </ResponsiveLayout>
    </keep-alive>

    <div v-else>
      <ResponsiveLayout v-if="$route.meta.isResponsive">
        <router-view />
      </ResponsiveLayout>
      <router-view v-else />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import ResponsiveLayout from "@/layout/ResponsiveLayout.vue";
import { useStore } from "vuex";
import { saveOfficialWebsiteSource } from "@/utils/config";
import { useI18n } from "vue-i18n";
export default {
  name: "App",
  components: {
    ResponsiveLayout,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const key = computed(() => route.fullPath);
    const { t, locale } = useI18n();
    // 根据域名设置主题色
    onMounted(() => {
      const hostname = window.location.hostname; // 获取当前域名
      const secondLevelDomain = hostname.split(".")[1]; // 提取第二部分域名, 对应官网来源
      // const thirdLevelDomain = hostname.split(".")[2]; // 提取第三部分域名，对应语言
      // const languageSave = localStorage.getItem("language");
      if (["elite", "tech"].includes(secondLevelDomain)) {
        saveOfficialWebsiteSource(secondLevelDomain || "");
      }
      // if (languageSave) {
      //   store.dispatch("language/setLanguage", languageSave);
      //   locale.value = languageSave;
      // } else {
      //   //根据域名的第三部分设置语言，默认英文
      //   if (
      //     [
      //       "zh",
      //       "en",
      //       "fr",
      //       "de",
      //       "it",
      //       "pl",
      //       "sl",
      //       "hr",
      //       "da",
      //       "sv",
      //       "nl",
      //       "es",
      //       "pt",
      //     ].includes(thirdLevelDomain)
      //   ) {
      //     store.dispatch("language/setLanguage", thirdLevelDomain);
      //     locale.value = thirdLevelDomain;
      //   } else {
      //     locale.value = "en";
      //     store.dispatch("language/setLanguage", "en");
      //   }
      // }
      //根据官网来源存储主题色
      if (secondLevelDomain === "elite") {
        store.dispatch("theme/setPrimaryColor", "#ff5016"); // elite主题色
      } else if (secondLevelDomain === "tech") {
        store.dispatch("theme/setPrimaryColor", "#5a87fa"); // tech主题色
      } else {
        store.dispatch("theme/setPrimaryColor", "#5a87fa"); // 默认主题色
      }
    });

    return {
      key,
    };
  },
};
</script>

<style lang="less">
@import "@/styles/variables.less";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f3f3f3;
  background-image: url("@/assets/images/backand.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 100%;
}

.ant-form-item-explain-error {
  text-align: left !important;
}
.btn-primary {
  background-color: var(--primary-color) !important;
  box-shadow: none !important;
}
</style>
