import axios from 'axios';
import store from '@/store'; // 直接导入 store，而不是使用 useStore
import { getToken, setToken, getUser } from '@/utils/auth';
import { message } from 'ant-design-vue';
import qs from 'qs';
import { stringify } from 'qs';
import router from '@/router'; // 引入 router 实例
import { getLocalizedPath } from '@/utils/router';
import { getOfficialWebsiteSource } from '@/utils/config';
import { languageMapping } from '@/utils/supportedLanguages';

// 创建 axios 实例
const http = axios.create({
  baseURL: '/open-api', // base URL
  timeout: 300000, // 请求超时
});

let pendingRequests = {}; // 用于管理请求的取消
let isRefreshing = false;
const tokenExpiredCode = [426, 401];

// 生成请求的唯一标识，添加Authorization，兼容refresh_token后马上调用pending接口的场景
const generateRequestKey = (config) => {
  const { url, method, params } = config;
  return `${url}_${method}_${JSON.stringify(params || {})}_${
    config.headers['Authorization'] || ''
  }`;
};

// 添加请求到待处理队列，并取消之前的请求
const addPendingRequest = (requestKey, cancel) => {
  if (pendingRequests[requestKey]) {
    // 取消之前的请求
    pendingRequests[requestKey]('请求取消：短时间内重复请求');
  }
  // 记录当前请求的取消函数
  pendingRequests[requestKey] = cancel;
};

// 执行待处理的请求
const processPendingRequests = (token) => {
  Object.values(pendingRequests).forEach((callback) => callback(token));
  pendingRequests = {};
};

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    const token = getToken();
    const locale = store.getters['language/currentLanguage'];
    config.headers['Accept-Language'] = locale
      ? languageMapping[locale]
      : 'en-us';
    if (!config.headers.Authorization) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['User-Agent-Web'] = 'warranty';
    config.headers['Source'] = getOfficialWebsiteSource();

    const requestKey = generateRequestKey(config);

    // 创建 cancelToken
    config.cancelToken = new axios.CancelToken((cancel) => {
      addPendingRequest(requestKey, cancel); // 管理请求的取消
    });

    return config;
  },
  (error) => {
    console.error('Request Error', error);
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  async (response) => {
    const requestKey = generateRequestKey(response.config);
    delete pendingRequests[requestKey]; // 请求完成后，移除记录
    if (response.status === 200) {
      // 添加统一的提示处理
      if (response?.data?.code) {
        message.error(response?.data?.msg);
        return Promise.reject();
      }
      return response.data;
    }
  },
  async (error) => {
    if (axios.isCancel(error)) {
      console.warn(error.message);
      return Promise.reject({ status: 'error', error: error.message });
    }

    if (error.response) {
      // 如果为426则代表失效
      if (tokenExpiredCode.includes(error.response.status)) {
        const response = error.response;
        if (!isRefreshing) {
          isRefreshing = true;
          return refreshToken()
            .then((newToken) => {
              setToken(newToken);
              processPendingRequests(newToken);
              response.config.headers['Authorization'] = `Bearer ${newToken}`;
              return http(response.config);
            })
            .catch((err) => {
              isRefreshing = false;
              console.error('Refresh token failed', err);
              store.dispatch('auth/removeUser'); // 清除用户信息
              const jumpPath = getLocalizedPath('/login');
              router.push(jumpPath);
              pendingRequests = [];
              return Promise.reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        } else {
          return new Promise((resolve) => {
            addPendingRequest(requestKey, (newToken) => {
              response.config.headers['Authorization'] = `Bearer ${newToken}`;
              resolve(http(response.config));
            });
          });
        }
      } else {
        const msg =
          error.response?.data?.message ||
          error.response?.statusText ||
          'Unknown Error';
        switch (error.response.status) {
          case 400:
          case 401:
          case 403:
          case 404:
          case 500:
            message.error(msg);
            break;
          default:
            message.error(msg);
        }
      }

      console.error('Response Error:', error);
      return Promise.reject(error.response);
    } else {
      console.error('Response Error:', error.message);
      return Promise.reject(error);
    }
  }
);

// 刷新 token 的请求方法
const refreshToken = () => {
  const user = getUser();
  return new Promise((resolve, reject) => {
    if (user && user?.refresh_token) {
      axios
        .get(
          `open-api/auth/v1/refreshToken?${stringify({
            refreshToken: user && user?.refresh_token,
          })}`,
          {
            headers: {
              Authorization: 'Basic YXBwOmFwcA==',
              'User-Agent-Web': 'warranty',
              Source: getOfficialWebsiteSource(),
            },
          }
        )
        .then((response) => {
          if (response?.data?.access_token) {
            const newToken = response?.data?.access_token;
            const data = response?.data;
            store.dispatch('auth/saveToken', newToken);
            store.dispatch('auth/saveUser', data);
            resolve(newToken);
          } else {
            reject(response?.msg);
          }
        })
        .catch((err) => {
          isRefreshing = false;
          reject(err);
        });
    } else {
      pendingRequests = []; // 清空请求队列
      isRefreshing = false; // 重置刷新状态，兼容复制两个tab页面同时刷新token导致失败的情况
      store.dispatch('auth/removeUser');
      const jumpPath = getLocalizedPath('/login');
      router.push(jumpPath);
    }
  });
};

export default http;
