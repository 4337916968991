// src/router/permission.js
import { getToken } from '@/utils/auth'; // 假设你有这个方法
import router from './index'; // 引入 router 实例
import { getLocalizedPath } from '@/utils/router';
import i18n from '@/i18n'; // 引入 i18n 实例
import { supportedLanguages } from '@/utils/supportedLanguages';
import store from '@/store'; // 直接导入 store，而不是使用 useStore
// const loadedLocales = new Set(i18n.global.availableLocales);
// 动态加载语言包
// async function loadLocaleMessages(locale) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const messages = await import(
//         /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
//       );
//       resolve(messages.default);
//     } catch (error) {
//       console.error(`Language pack ${locale} load failed:`, error);
//       reject(null);
//     }
//   });
// }
// 获取第一个有权限的页面
function getFirstAuthorizedPage(lang) {
  const accessibleRoutes = router.getRoutes().filter((route) => {
    return !(route.meta?.hideMenu || route.meta?.isResponsive);
  });

  return accessibleRoutes.length > 0
    ? `/${lang}/accessibleRoutes[0].path`
    : `${lang}/warranty`;
}

// 设置全局路由守卫
function setupRouteGuard() {
  router.beforeEach(async (to, from, next) => {
    const token = getToken();
    const lang = to.params.lang || 'en'; // 获取语言参数
    const newLang = to.params.lang || 'en';
    let oldLang = localStorage.getItem('language') || 'de';
    //兼容输入路由为/，路由被重定向到新的地址，i18n.global.locale.value变为en的情况
    if (
      to.fullPath !== '/' &&
      to.redirectedFrom &&
      to.redirectedFrom.fullPath === '/'
    ) {
      i18n.global.locale.value = oldLang;
    }
    // 处理语言参数丢失的情况
    if (!to.params.lang) {
      const defaultLang = localStorage.getItem('language') || 'en';
      const cleanPath = to.fullPath.startsWith('/')
        ? to.fullPath.slice(1)
        : to.fullPath;

      return next(`/${defaultLang}/${cleanPath}`.replace(/\/+/g, '/'));
    }
    if (newLang !== oldLang) {
      //判断newLang是否在supportedLanguages中，不在则不进行替换，且路由仍为之前的路由
      if (!supportedLanguages.includes(newLang)) {
        i18n.global.locale.value = oldLang;
        localStorage.setItem('language', oldLang);
        store.dispatch('language/setLanguage', oldLang);
        return next(`/${oldLang}/${to.fullPath}`);
      }
      try {
        // 2.1 加载未缓存的语言包
        // if (!loadedLocales.has(newLang)) {
        // i18n.global.setLocaleMessage(newLang, messages);
        // 2.2 更新 i18n 语言
        store.dispatch('language/setLanguage', newLang);
        i18n.global.locale.value = newLang;
        localStorage.setItem('language', newLang);
        // loadLocaleMessages(newLang).then((messages) => {
        //   if (messages) {
        //     i18n.global.setLocaleMessage(newLang, messages);
        //     loadedLocales.add(newLang);
        //     // 2.2 更新 i18n 语言
        //     i18n.global.locale = newLang;
        //     localStorage.setItem('language', newLang);
        //   } else {
        //     console.warn(
        //       `Fallback to ${oldLang}, invalid locale: ${newLang}`
        //     );
        //     return next(`/${oldLang}${to.fullPath}`);
        //   }
        // });
        // }
      } catch (error) {
        console.error('Language switch failed:', error);
        return next(false); // 取消本次导航
      }
    }

    // 登录页特殊处理
    if (to.name === 'Login') {
      if (token) {
        next(getFirstAuthorizedPage(lang));
      } else {
        next();
      }
      return;
    }

    // 需要授权的页面
    if (to.meta?.requiresAuth) {
      if (token) {
        next();
      } else {
        next(`/${lang}/login`);
      }
    } else {
      next();
    }
  });
}
// 添加 chunk 加载错误处理
router.onError((error) => {
  if (/ChunkLoadError/.test(error.message)) {
    window.location.href = getLocalizedPath('/login'); // 强制刷新恢复状态
  }
});

export default setupRouteGuard;
