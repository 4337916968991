/**
 *  Language.js
 *  语言设置
 * 支持的语言中文含义: 中文, 英文, 法文, 德文, 意文, 波兰文, 斯洛文尼亚文, 克罗地亚文, 丹麦文, 瑞典文, 荷兰文, 西班牙文, 葡萄牙文
 */
export const supportedLanguages = [
  'zh',
  'en',
  'fr',
  'de',
  'it',
  'pl',
  'sl',
  'hr',
  'da',
  'sv',
  'nl',
  'es',
  'pt',
  'ga',
  'et',
  'lv',
  'lt',
  'bg',
  'el',
];

// 语言映射，匹配后端服务器
export const languageMapping = {
  zh: 'zh-cn',
  en: 'en-us',
  fr: 'fr-fr',
  de: 'de-de',
  it: 'it-it',
  da: 'da-dk',
  es: 'es-es',
  pt: 'pt-pt',
  sv: 'sv-se',
  pl: 'pl-pl',
  hr: 'hr-hr',
  nl: 'nl-nl',
  sl: 'sl-sl',
  ga: 'ga-IE',
  et: 'et-EE',
  lv: 'lv-LV',
  lt: 'lt-LT',
  bg: 'bg-BG',
  el: 'el-GR',
};
